<!-- 
  Página com formulário para inserção de novos produtos
-->

<script>
import router from "@/router";
import CampoTexto from '../../components/campos_dados/campo_texto';
import CampoSelect from '../../components/campos_dados/campo_select';
import TiposDeCampo from '../../components/campos_dados/tipos_de_campo';
import { baseApiUrl } from '@/global'
import axios from "axios";
import moment from "moment";

/**
 * Campos básicos de informação para criação do produto
 */
const camposBases = {
  codIdentificacao: new CampoTexto({
    nome: 'cod_identificacao',
    label: 'Código',
    icone: 'fas fa-file-alt',
    valor: '',
    regras: [],
  }),
  descricao: new CampoTexto({
    nome: 'descricao',
    label: 'Descrição',
    icone: 'fas fa-stream',
    valor: '',
    regras: [],
  }),
  marca: new CampoTexto({
    nome: 'marca',
    label: 'Marca',
    icone: 'fas fa-copyright',
    valor: '',
    regras: [],
  }),
  grupo: new CampoTexto({
    nome: 'grupo',
    label: 'Grupo',
    icone: 'fas fa-layer-group',
    valor: '',
    regras: [],
  }),
};

/**
 * Campos adicionais, dados restantes.
 */
const camposDadosRestantes = {
  modal: new CampoSelect({
    nome: 'modal',
    label: 'Modal',
    icone: 'fas fa-th-list',
    valor: '',
    itens: [],
    regras: [],
  }),
  doca: new CampoSelect({
    nome: 'doca',
    label: 'Doca',
    icone: 'fas fa-folder',
    valor: '',
    itens: [],
    regras: [],
  }),
  totalAgregados: new CampoTexto({
    nome: 'total_agregados',
    label: 'Total agregados',
    icone: 'fas fa-poll-h',
    valor: '',
    regras: [],
    numerico: true,
  }),
  limiteCubagem: new CampoTexto({
    nome: 'limite_cubagem',
    label: 'Limite de cubagem',
    icone: 'fas fa-cubes',
    valor: '',
    regras: [],
    numerico: true,
  }),
  embalamento: new CampoSelect({
    nome: 'embalamento',
    label: 'Habilitar regra de embalamento',
    icone: 'fas fa-scroll',
    valor: 'Não',
    itens: ['Sim', 'Não'],
    explicacao: 'O sistema encaminhará as medidas do produto para procura de caixa pré-cadastrada.',
  })
};

/**
 * Campos de dimensões para produtos, agregados, 
 * volumes extras etc.
 */
class Dimensoes {
  comprimento = new CampoTexto({
    nome: 'comprimento',
    label: 'Comprimento (m)',
    icone: 'fas fa-ruler-horizontal',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  largura = new CampoTexto({
    nome: 'largura',
    label: 'Largura (m)',
    icone: 'fas fa-ruler',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  altura = new CampoTexto({
    nome: 'altura',
    label: 'Altura (m)',
    icone: 'fas fa-ruler-vertical',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  cubagem = new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem (m³)',
    icone: 'fas fa-cube',
    valor: 0,
    bloqueado: true,
    numerico: true,
  });
  peso = new CampoTexto({
    nome: 'peso',
    label: 'Peso (kg)',
    icone: 'fas fa-weight-hanging',
    valor: '',
    numerico: true,
  });
  volume = new CampoTexto({
    nome: 'volume',
    label: 'Volume (UN)',
    icone: 'fas fa-boxes',
    valor: '',
    numerico: true,
  });
};

class DimensoesAg {
  volume = new CampoTexto({
    nome: 'volume',
    label: 'Volume (UN)',
    icone: 'fas fa-boxes',
    valor: '',
    numerico: true,
  });
  comprimento = new CampoTexto({
    nome: 'comprimento',
    label: 'Comprimento (m)',
    icone: 'fas fa-ruler-horizontal',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  largura = new CampoTexto({
    nome: 'largura',
    label: 'Largura (m)',
    icone: 'fas fa-ruler',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  altura = new CampoTexto({
    nome: 'altura',
    label: 'Altura (m)',
    icone: 'fas fa-ruler-vertical',
    valor: '',
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = (this.altura.valor * this.comprimento.valor * this.largura.valor).toFixed(6);
    }
  });
  cubagem = new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem (m³)',
    icone: 'fas fa-cube',
    valor: 0,
    bloqueado: true,
    numerico: true,
  });
  peso = new CampoTexto({
    nome: 'peso',
    label: 'Peso (kg)',
    icone: 'fas fa-weight-hanging',
    valor: '',
    numerico: true,
  });
};

/** Campos de dimensões do novo produto. */
const camposDimensoes = new Dimensoes();

const camposDimensoesAgregados = new DimensoesAg();

const camposDimensoesVolumes = new Dimensoes();

const estadoAgregados = {
  /** @type {?Dimensoes[]} */
  estadoAtual: [],

  qtd: 0,
  showEdit: false,
  index: null,

  adicionar() {
    this.qtd++;
    this.estadoAtual.push(new DimensoesAg());
    this.estadoAtual[this.qtd-1].comprimento.valor = camposDimensoesAgregados.comprimento.valor;
    this.estadoAtual[this.qtd-1].largura.valor = camposDimensoesAgregados.largura.valor;
    this.estadoAtual[this.qtd-1].altura.valor = camposDimensoesAgregados.altura.valor;
    this.estadoAtual[this.qtd-1].cubagem.valor = camposDimensoesAgregados.cubagem.valor;
    this.estadoAtual[this.qtd-1].peso.valor = camposDimensoesAgregados.peso.valor;
    this.estadoAtual[this.qtd-1].volume.valor = camposDimensoesAgregados.volume.valor;

    camposDimensoesAgregados.comprimento.valor = '';
    camposDimensoesAgregados.largura.valor = '';
    camposDimensoesAgregados.altura.valor = '';
    camposDimensoesAgregados.cubagem.valor = '';
    camposDimensoesAgregados.peso.valor = '';
    camposDimensoesAgregados.volume.valor = '';
  },

  /** @param {number} index */
  remover(index) {
    this.qtd--;
    this.estadoAtual.splice(index, 1);
  },

  editar(index) {
    camposDimensoesAgregados.comprimento.valor = this.estadoAtual[index].comprimento.valor;
    camposDimensoesAgregados.largura.valor = this.estadoAtual[index].largura.valor;
    camposDimensoesAgregados.altura.valor = this.estadoAtual[index].altura.valor;
    camposDimensoesAgregados.cubagem.valor = this.estadoAtual[index].cubagem.valor;
    camposDimensoesAgregados.peso.valor = this.estadoAtual[index].peso.valor;
    camposDimensoesAgregados.volume.valor = parseInt(this.estadoAtual[index].volume.valor);

    this.index = index;
    this.showEdit = true;
  },

  confirmarEdicao() {
    this.estadoAtual[this.index].comprimento.valor = camposDimensoesAgregados.comprimento.valor;
    this.estadoAtual[this.index].largura.valor = camposDimensoesAgregados.largura.valor;
    this.estadoAtual[this.index].altura.valor = camposDimensoesAgregados.altura.valor;
    this.estadoAtual[this.index].cubagem.valor = camposDimensoesAgregados.cubagem.valor;
    this.estadoAtual[this.index].peso.valor = camposDimensoesAgregados.peso.valor;
    this.estadoAtual[this.index].volume.valor = camposDimensoesAgregados.volume.valor;

    this.limpar();
  },

  limpar() {
    camposDimensoesAgregados.comprimento.valor = '';
    camposDimensoesAgregados.largura.valor = '';
    camposDimensoesAgregados.altura.valor = '';
    camposDimensoesAgregados.cubagem.valor = '';
    camposDimensoesAgregados.peso.valor = '';
    camposDimensoesAgregados.volume.valor = '';

    this.showEdit = false;
  }
};

const estadoVolumesExtras = {
  /** @type {?Dimensoes[]} */
  estadoAtual: [],

  qtd: 0,
  showEdit: false,
  index: null,

  adicionar() {
    this.qtd++;
    this.estadoAtual.push(new Dimensoes());
    this.estadoAtual[this.qtd-1].comprimento.valor = camposDimensoesVolumes.comprimento.valor;
    this.estadoAtual[this.qtd-1].largura.valor = camposDimensoesVolumes.largura.valor;
    this.estadoAtual[this.qtd-1].altura.valor = camposDimensoesVolumes.altura.valor;
    this.estadoAtual[this.qtd-1].cubagem.valor = camposDimensoesVolumes.cubagem.valor;
    this.estadoAtual[this.qtd-1].peso.valor = camposDimensoesVolumes.peso.valor;
    this.estadoAtual[this.qtd-1].volume.valor = camposDimensoesVolumes.volume.valor;

    camposDimensoesVolumes.comprimento.valor = '';
    camposDimensoesVolumes.largura.valor = '';
    camposDimensoesVolumes.altura.valor = '';
    camposDimensoesVolumes.cubagem.valor = '';
    camposDimensoesVolumes.peso.valor = '';
    camposDimensoesVolumes.volume.valor = '';
  },

  remover(index) {
    this.qtd--;
    this.estadoAtual.splice(index, 1);
  },

  editar(index) {
    camposDimensoesVolumes.comprimento.valor = this.estadoAtual[index].comprimento.valor;
    camposDimensoesVolumes.largura.valor = this.estadoAtual[index].largura.valor;
    camposDimensoesVolumes.altura.valor = this.estadoAtual[index].altura.valor;
    camposDimensoesVolumes.cubagem.valor = this.estadoAtual[index].cubagem.valor;
    camposDimensoesVolumes.peso.valor = this.estadoAtual[index].peso.valor;
    camposDimensoesVolumes.volume.valor = parseInt(this.estadoAtual[index].volume.valor);

    this.index = index;
    this.showEdit = true;
  },

  confirmarEdicao() {
    this.estadoAtual[this.index].comprimento.valor = camposDimensoesVolumes.comprimento.valor;
    this.estadoAtual[this.index].largura.valor = camposDimensoesVolumes.largura.valor;
    this.estadoAtual[this.index].altura.valor = camposDimensoesVolumes.altura.valor;
    this.estadoAtual[this.index].cubagem.valor = camposDimensoesVolumes.cubagem.valor;
    this.estadoAtual[this.index].peso.valor = camposDimensoesVolumes.peso.valor;
    this.estadoAtual[this.index].volume.valor = camposDimensoesVolumes.volume.valor;

    this.limpar();
  },

  limpar() {
    camposDimensoesVolumes.comprimento.valor = '';
    camposDimensoesVolumes.largura.valor = '';
    camposDimensoesVolumes.altura.valor = '';
    camposDimensoesVolumes.cubagem.valor = '';
    camposDimensoesVolumes.peso.valor = '';
    camposDimensoesVolumes.volume.valor = '';

    this.showEdit = false;
  }
}

const camposAdicional = {
  prazo: new CampoTexto({
    nome: 'prazo',
    label: 'Prazo',
    icone: 'fas fa-calendar',
    valor: '',
    regras: [],
  }),
  valor: new CampoTexto({
    nome: 'valor',
    label: 'Valor',
    icone: 'fas fa-coins',
    valor: '',
    regras: [],
    numerico: true,
  }),
  tipo: new CampoSelect({
    nome: 'tipo',
    label: 'Tipo',
    icone: 'fas fa-window-restore',
    valor: '',
    itens: ['Valor', 'Porcentagem'],
    regras: [],
  }),
  calculo: new CampoSelect({
    nome: 'calculo',
    label: 'Cálculo',
    icone: 'fas fa-calculator',
    valor: '',
    itens: ['Frete', 'Produto'],
    regras: [],
  }),
};

/** 
 * Campos com valores de venda nos canais de venda.
 * TODO: deve puxar os canais de venda da API.
 */
const camposValoresVenda = {
  valor1: new CampoTexto({
    nome: 'valor1',
    label: 'Madeira Madeira',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor2: new CampoTexto({
    nome: 'valor2',
    label: 'Netshoes',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor3: new CampoTexto({
    nome: 'valor3',
    label: 'Leroy Merlin',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor4: new CampoTexto({
    nome: 'valor4',
    label: 'Via Varejo',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor5: new CampoTexto({
    nome: 'valor5',
    label: 'Convertize',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor6: new CampoTexto({
    nome: 'valor6',
    label: 'Carrefour',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor7: new CampoTexto({
    nome: 'valor7',
    label: 'Conecta',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor8: new CampoTexto({
    nome: 'valor8',
    label: 'Renner',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor9: new CampoTexto({
    nome: 'valor9',
    label: 'Shopee',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
};

function changeEmbalamento() {
  if(this.camposDadosRestantes.embalamento.valor == 'Sim') {
    this.camposDadosRestantes.embalamento.valor = 'Não';
  } else {
    this.camposDadosRestantes.embalamento.valor = 'Sim';
  }
}

async function gravarDados() {
  let url = `${baseApiUrl}/produtos-gravar`;
  let date = moment(new Date()).format("YYYY-MM-DD");
  let valor_venda = [];
  let agregados = [];
  let vol_extra = [];
  let tipo_adc = "";
  let calc_adc = "";

  if(this.camposAdicional.tipo.valor == 'Valor'){
    tipo_adc = "V";
  }else{
    if(this.camposAdicional.tipo.valor == 'Porcentagem'){
      tipo_adc = "P";
    }
  }

  if(this.camposAdicional.calculo.valor == 'Frete'){
    calc_adc = "F";
  }else{
    if(this.camposAdicional.calculo.valor == 'Produto'){
      calc_adc = "P";
    }
  }

  if(this.estadoAgregados.estadoAtual.length != 0){
    for(let cont = 0; cont < this.estadoAgregados.estadoAtual.length; cont++){
      agregados.push({
        "altura": this.estadoAgregados.estadoAtual[cont].altura.valor,
        "comprimento": this.estadoAgregados.estadoAtual[cont].comprimento.valor,
        "cubagem": this.estadoAgregados.estadoAtual[cont].cubagem.valor,
        "largura": this.estadoAgregados.estadoAtual[cont].largura.valor,
        "peso": this.estadoAgregados.estadoAtual[cont].peso.valor,
        "volume": this.estadoAgregados.estadoAtual[cont].volume.valor,
      });
    }
  }

  if(this.estadoVolumesExtras.estadoAtual.length != 0){
    for(let cont = 0; cont < this.estadoVolumesExtras.estadoAtual.length; cont++){
      vol_extra.push({
        "altura": this.estadoVolumesExtras.estadoAtual[cont].altura.valor,
        "comprimento": this.estadoVolumesExtras.estadoAtual[cont].comprimento.valor,
        "cubagem": this.estadoVolumesExtras.estadoAtual[cont].cubagem.valor,
        "largura": this.estadoVolumesExtras.estadoAtual[cont].largura.valor,
        "peso": this.estadoVolumesExtras.estadoAtual[cont].peso.valor,
        "volume": this.estadoVolumesExtras.estadoAtual[cont].volume.valor,
      })
    }
  }

  valor_venda = ({
    "Madeira_Madeira": Object.values(this.camposValoresVenda)[0].valor,
    "Netshoes": Object.values(this.camposValoresVenda)[1].valor,
    "Leroy_Merlin": Object.values(this.camposValoresVenda)[2].valor,
    "Via_Varejo": Object.values(this.camposValoresVenda)[3].valor,
    "Convertize": Object.values(this.camposValoresVenda)[4].valor,
    "Carrefour": Object.values(this.camposValoresVenda)[5].valor,
    "Conecta": Object.values(this.camposValoresVenda)[6].valor,
    "Renner": Object.values(this.camposValoresVenda)[7].valor,
    "Shopee": Object.values(this.camposValoresVenda)[8].valor,
  });

  if(valor_venda.Madeira_Madeira == ""){
    valor_venda.Madeira_Madeira = 0;
  }
  if(valor_venda.Netshoes == ""){
    valor_venda.Netshoes = 0;
  }
  if(valor_venda.Leroy_Merlin == ""){
    valor_venda.Leroy_Merlin = 0;
  }
  if(valor_venda.Via_Varejo == ""){
    valor_venda.Via_Varejo = 0;
  }
  if(valor_venda.Convertize == ""){
    valor_venda.Convertize = 0;
  }
  if(valor_venda.Carrefour == ""){
    valor_venda.Carrefour = 0;
  }
  if(valor_venda.Conecta == ""){
    valor_venda.Conecta = 0;
  }
  if(valor_venda.Renner == ""){
    valor_venda.Renner = 0;
  }
  if(valor_venda.Shopee == ""){
    valor_venda.Shopee = 0;
  }

  if(this.camposDadosRestantes.embalamento.valor == 'Sim')
    this.embalamentoFinal = 1;
  else
    this.embalamentoFinal = 0;

  if(this.usarMedidasCotacao)
    this.ler_medidas = 1;
  else
    this.ler_medidas = 0;

  await axios
    .post(url, {
      "cliente": this.codigo_empresa,
      "cod_identificacao": this.camposBases.codIdentificacao.valor,
      "descricao": this.camposBases.descricao.valor,
      "limite_cubagem": this.camposDadosRestantes.limiteCubagem.valor,
      "marca": this.camposBases.marca.valor,
      "grupo": this.camposBases.grupo.valor,
      "doca_id": this.camposDadosRestantes.doca.valor,
      "modal": this.camposDadosRestantes.modal.valor,
      "ler_medidas": this.ler_medidas,
      "total_agregados": this.camposDadosRestantes.totalAgregados.valor,
      "embalamento": this.embalamentoFinal,
      "comprimento": this.camposDimensoes.comprimento.valor,
      "largura": this.camposDimensoes.largura.valor,
      "altura": this.camposDimensoes.altura.valor,
      "cubagem": this.camposDimensoes.cubagem.valor,
      "peso": this.camposDimensoes.peso.valor,
      "volume": this.camposDimensoes.volume.valor,
      "prazo": this.camposAdicional.prazo.valor,
      "valor": parseFloat(this.camposAdicional.valor.valor),
      "tipo": tipo_adc,
      "calculo": calc_adc,
      "preco_venda": {
        'valor1': parseFloat(valor_venda.Madeira_Madeira),
        'valor2': parseFloat(valor_venda.Netshoes),
        'valor3': parseFloat(valor_venda.Leroy_Merlin),
        'valor4': parseFloat(valor_venda.Via_Varejo),
        'valor5': parseFloat(valor_venda.Convertize),
        'valor6': parseFloat(valor_venda.Carrefour),
        'valor7': parseFloat(valor_venda.Conecta),
        'valor8': parseFloat(valor_venda.Renner),
        'valor9': parseFloat(valor_venda.Shopee),
      },
      "agregados": agregados,
      "volume_extra": vol_extra,
      "criado_em": date
    }).then((res) => {
      this.$swal({
        title: 'Sucesso!!!',
        text: `${res.data.dados}`,
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      }).finally(() => (router.push({ path: '/cadastro-produtos' })));
    }).catch(() => {
      this.$swal({
        title: 'Que pena!!!',
        html: 'Não foi possível adicionar o produto.<br>Verifique se as informações estão corretas.',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      });
    })
}

function fecharPagina(){
  router.push({
    path: '/cadastro-produtos',
  });
}

function updateValue(campo) {
  campo.valor = this.formatNumber(campo.valor, campo.nome);
}

function formatNumber(value, nome) {
  const cleanValue = value.replace(/\D/g, '');
      
  if (cleanValue && nome != 'peso') {
    const formatted = (parseInt(cleanValue, 10) / 100).toFixed(2);
    return formatted;
  } else if(cleanValue && nome == 'peso') {
    const formatted = (parseInt(cleanValue, 10) / 1000).toFixed(3);
    return formatted;
  } else if(!cleanValue && nome != 'peso') {
    return '0.00';
  } else {
    return '0.000';
  }
}

function disabledAdc() {
  if(this.camposAdicional.valor.valor == '' || this.camposAdicional.valor.valor == null ||
  this.camposAdicional.valor.valor == '0.00') {
    return true;
  }
}

function limparCampos(){
  this.camposBases.codIdentificacao.valor = '';
  this.camposBases.descricao.valor = '';
  this.camposDadosRestantes.modal.valor = '';

  this.camposDadosRestantes.limiteCubagem.valor = '';
  this.camposBases.marca.valor = '';
  this.camposBases.grupo.valor = '';
  this.camposDadosRestantes.totalAgregados.valor = '';
  this.camposDadosRestantes.doca.valor = '';
  this.camposDadosRestantes.embalamento.valor = 'Não';

  this.camposDimensoes.comprimento.valor = '';
  this.camposDimensoes.altura.valor = '';
  this.camposDimensoes.largura.valor = '';
  this.camposDimensoes.cubagem.valor = '';
  this.camposDimensoes.peso.valor = '';
  this.camposDimensoes.volume.valor = '';

  this.camposDimensoesAgregados.comprimento.valor = '';
  this.camposDimensoesAgregados.altura.valor = '';
  this.camposDimensoesAgregados.largura.valor = '';
  this.camposDimensoesAgregados.cubagem.valor = '';
  this.camposDimensoesAgregados.peso.valor = '';
  this.camposDimensoesAgregados.volume.valor = '';

  this.camposDimensoesVolumes.comprimento.valor = '';
  this.camposDimensoesVolumes.altura.valor = '';
  this.camposDimensoesVolumes.largura.valor = '';
  this.camposDimensoesVolumes.cubagem.valor = '';
  this.camposDimensoesVolumes.peso.valor = '';
  this.camposDimensoesVolumes.volume.valor = '';

  this.estadoAgregados.qtd = 0;
  this.estadoAgregados.estadoAtual = [];

  this.estadoVolumesExtras.qtd = 0;
  this.estadoVolumesExtras.estadoAtual = [];

  this.camposAdicional.prazo.valor = '';
  this.camposAdicional.valor.valor = '';
  this.camposAdicional.tipo.valor = '';
  this.camposAdicional.calculo.valor = '';

  this.camposValoresVenda.valor1.valor = '';
  this.camposValoresVenda.valor2.valor = '';
  this.camposValoresVenda.valor3.valor = '';
  this.camposValoresVenda.valor4.valor = '';
  this.camposValoresVenda.valor5.valor = '';
  this.camposValoresVenda.valor6.valor = '';
  this.camposValoresVenda.valor7.valor = '';
  this.camposValoresVenda.valor8.valor = '';
}

export default {
  name: 'InserirProduto',

  data: () => ({
    codigo_empresa: null,
    embalamentoFinal: null,
    ler_medidas: 0,
    usarMedidasCotacao: 0,
    grid: {
      produtos: {
        cabecalho: [
          { text: 'Comprimento', value: 'comprimento', sortable: false },
          { text: 'Largura', value: 'largura', sortable: false },
          { text: 'Altura', value: 'altura', sortable: false },
          { text: 'Cubagem', value: 'cubagem', sortable: false },
          { text: 'Peso', value: 'peso', sortable: false },
          { text: 'Volume', value: 'volume', sortable: false },
          { text: 'Ações', value: 'acoes', sortable: false, align: 'center' },
        ]
      },
    },

    TiposDeCampo,
    camposBases,
    camposDimensoes,
    camposDimensoesAgregados,
    camposDimensoesVolumes,
    camposAdicional,
    camposValoresVenda,
    camposDadosRestantes,
    estadoAgregados,
    estadoVolumesExtras,
  }),

  methods: {
    fecharPagina,
    limparCampos,
    gravarDados,
    changeEmbalamento,
    updateValue,
    formatNumber,
    disabledAdc
  },
  
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    this.limparCampos();
  }
}

</script>

<template>
  <article>
    <!-- Título -->
    <v-toolbar flat class="fixed-bar">
      <v-icon size="100">
        fas fa-plus
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
        Inserir produto
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn @click="gravarDados()" class="mr-2" type="submit" color="#018656" style="color: #FFFFFF; width: 200px; height: 38px;">
        <v-icon dense class="mr-2">fas fa-check-circle</v-icon>
        GRAVAR DADOS
      </v-btn>

      <v-app-bar-nav-icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <v-form ref="form" style="margin: 5px">
      <!-- Campos bases -->
      <v-card outlined>
        <div class="d-flex align-end">
          <v-card-title class="subtitle-1 font-weight-medium">Produto:</v-card-title>
          <v-checkbox v-model="usarMedidasCotacao" label="Usar na Cotação" color="#018656" dense class="ml-2"></v-checkbox>
        </div>
        <v-card-text class="pt-0">
          <v-row dense>
            <v-col cols="12" sm="6" :md="campo.label == 'Descrição' ? 4 : campo.label == 'Marca' || campo.label == 'Grupo' ? 3 : 2"
            v-for="campo in camposBases" :key="campo.nome">
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar" hide-details class="mb-1" persistent-placeholder>
              </v-text-field>

              <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar" hide-details class="mb-1" persistent-placeholder>
              </v-select>
            </v-col>
          </v-row>

          <!-- Demais campos -->
          <v-row dense align="start">
            <div style="flex: 0 0 20%; max-width: 20%; padding: 4px;" class="d-flex justify-center" v-for="campo in camposDadosRestantes" :key="campo.nome">
              <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" color="#018656"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                @change="campo.aoMudar" hide-details @input="campo.label == 'Limite de cubagem' ? updateValue(campo) : ''"
                :hide-spin-buttons="campo.label == 'Limite de cubagem'" persistent-placeholder>
              </v-text-field>

              <v-select v-else-if="campo.label == 'Habilitar regra de embalamento'" dense outlined readonly :name="campo.nome" append-icon=""
                :label="campo.label" autocomplete="off" v-model="campo.valor" :items="campo.itens" color="#018656" hide-details
                @mousedown="changeEmbalamento()" @click="changeEmbalamento()" persistent-placeholder>
              </v-select>

              <v-select v-else dense outlined @click="campo.aoClicar" :disabled="campo.bloqueado" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                :items="campo.itens" color="#018656" @change="campo.aoMudar" hide-details persistent-placeholder>
              </v-select>
            </div>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Campos de dimensões do produto -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1 pb-3 font-weight-medium">Dimensões:</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="2" v-for="campo in camposDimensoes" :key="campo.nome">
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @input="campo.label != 'Volume (UN)' ? updateValue(campo) : ''"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" persistent-placeholder
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar" hide-details :hide-spin-buttons="campo.label != 'Volume (UN)'">
              </v-text-field>

              <v-select dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar" persistent-placeholder
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar" hide-details>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Adicional -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1 pb-3 font-weight-medium">Adicional:</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposAdicional" :key="campo.nome">
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar" hide-spin-buttons
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" persistent-placeholder
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar" hide-details @input="campo.label == 'Valor' ? updateValue(campo) : ''">
              </v-text-field>

              <abbr v-else-if="campo.tipoDeCampo == TiposDeCampo.select" :title="disabledAdc() ? 'Insira um valor adicional para habilitar o campo' : ''">
                <v-select outlined dense @click="campo.aoClicar" :disabled="disabledAdc()" :append-icon="campo.icone"
                  :name="campo.nome" :label="campo.label" :items="campo.itens" color="#018656" :rules="campo.regras"
                  :hint="disabledAdc() ? 'Informe um valor adicional para habilitar o campo!' : ''" :persistent-hint="disabledAdc()"
                  autocomplete="off" v-model="campo.valor" @change="campo.aoMudar" :hide-details="!disabledAdc()" persistent-placeholder>
                </v-select>
              </abbr>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Valor de venda -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1 font-weight-medium">Valor de venda (R$):</v-card-title>
        <v-card-text>
          <v-row dense style="justify-content: space-around;">
            <div style="flex: 0 0 10.7%; max-width: 11%;" v-for="campo in camposValoresVenda" :key="campo.nome">
              <p class="font-weight-bold">{{ campo.label }}</p>
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" autocomplete="off" v-model="campo.valor" :rules="campo.regras" color="#018656"
                @input="updateValue(campo)" hide-details hide-spin-buttons>
              </v-text-field>
            </div>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Agregados -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1 pb-2 font-weight-medium">Agregados:</v-card-title>
        <v-card-text class="pt-0 pb-0">
          <!-- Campos -->
          <v-card-text class="pt-0 pl-0 pr-0">
            <v-row dense style="margin-top: 0px;">
              <v-col cols=" 12" sm="6" md="2" v-for="campo in camposDimensoesAgregados" :key="campo.nome">
                <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar" hide-details
                  :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" hide-spin-buttons
                  :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras" persistent-placeholder
                  color="#018656" @input="campo.label != 'Volume (UN)' ? updateValue(campo) : ''" @change="campo.aoMudar">

                  <!-- Actions -->
                  <template #append-outer>
                    <template v-if="!estadoAgregados.showEdit">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="camposDimensoesAgregados.comprimento.valor == '' || camposDimensoesAgregados.largura.valor == '' || camposDimensoesAgregados.altura.valor == '' ||
                          camposDimensoesAgregados.cubagem.valor == '' || camposDimensoesAgregados.peso.valor == '' || camposDimensoesAgregados.volume.valor == ''"
                          v-on="on" v-if="campo.label == 'Peso (kg)'" small icon dense @click="estadoAgregados.adicionar()">
                            <v-icon color="#018656">fas fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar Agregado</span>
                      </v-tooltip>
                    </template>

                    <template v-else>
                      <div class="d-flex align-start">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="camposDimensoesAgregados.comprimento.valor == '' || camposDimensoesAgregados.largura.valor == '' || camposDimensoesAgregados.altura.valor == '' ||
                            camposDimensoesAgregados.cubagem.valor == '' || camposDimensoesAgregados.peso.valor == '' || camposDimensoesAgregados.volume.valor == ''"
                            v-on="on" v-if="campo.label == 'Peso (kg)'" fab class="elevation-1 ma-0" x-small dense @click="estadoAgregados.confirmarEdicao()" color="primary">
                              <v-icon small color="white">fas fa-pen</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="camposDimensoesAgregados.comprimento.valor == '' || camposDimensoesAgregados.largura.valor == '' || camposDimensoesAgregados.altura.valor == '' ||
                            camposDimensoesAgregados.cubagem.valor == '' || camposDimensoesAgregados.peso.valor == '' || camposDimensoesAgregados.volume.valor == ''"
                            v-on="on" v-if="campo.label == 'Peso (kg)'" fab class="elevation-1 ml-1" x-small dense @click="estadoAgregados.limpar()" color="#FF0000">
                              <v-icon small color="white">fas fa-times</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancelar</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Tabela Agregados -->
          <v-card v-if="estadoAgregados.qtd > 0" outlined style="margin-bottom: 15px;">
            <v-card-text>
              <v-data-table dense :headers="grid.produtos.cabecalho" hide-default-footer :items="estadoAgregados.estadoAtual">

                <template #item.comprimento="{ item }">
                  {{ item.comprimento.valor }}
                </template>

                <template #item.largura="{ item }">
                  {{ item.largura.valor }}
                </template>

                <template #item.altura="{ item }">
                  {{ item.altura.valor }}
                </template>

                <template #item.cubagem="{ item }">
                  {{ item.cubagem.valor }}
                </template>

                <template #item.peso="{ item }">
                  {{ item.peso.valor }}
                </template>

                <template #item.volume="{ item }">
                  {{ item.volume.valor }}
                </template>

                <template #item.acoes="{ item, index }">
                  <div class="d-flex justify-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" type="button" icon class="mr-2" @click="estadoAgregados.editar(index)">
                          <v-icon small color="primary">fas fa-pen</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon type="button" @click="estadoAgregados.remover(index)">
                          <v-icon small color="red">fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Excluir Agregado</span>
                    </v-tooltip>
                  </div>
                </template>

              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <!-- Volumes extras -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1 pb-2 font-weight-medium">Volumes extras:</v-card-title>
        <v-card-text class="pt-0 pb-0">
          <!-- Campos -->
          <v-card-text class="pt-0 pl-0 pr-0">
            <v-row dense style="margin-top: 0px;">
              <v-col cols=" 12" sm="6" md="2" v-for="campo in camposDimensoesVolumes" :key="campo.nome">
                <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar" hide-details
                  :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" hide-spin-buttons
                  :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras" persistent-placeholder
                  color="#018656" @input="campo.label != 'Volume (UN)' ? updateValue(campo) : ''" @change="campo.aoMudar">

                  <!-- Actions -->
                  <template #append-outer>
                    <template v-if="!estadoVolumesExtras.showEdit">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="camposDimensoesVolumes.comprimento.valor == '' || camposDimensoesVolumes.largura.valor == '' || camposDimensoesVolumes.altura.valor == '' ||
                          camposDimensoesVolumes.cubagem.valor == '' || camposDimensoesVolumes.peso.valor == '' || camposDimensoesVolumes.volume.valor == ''"
                          v-on="on" v-if="campo.label == 'Volume (UN)'" small icon dense @click="estadoVolumesExtras.adicionar()">
                            <v-icon color="#018656">fas fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar Volume Extra</span>
                      </v-tooltip>
                    </template>

                    <template v-else>
                      <div class="d-flex align-start">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="camposDimensoesVolumes.comprimento.valor == '' || camposDimensoesVolumes.largura.valor == '' || camposDimensoesVolumes.altura.valor == '' ||
                            camposDimensoesVolumes.cubagem.valor == '' || camposDimensoesVolumes.peso.valor == '' || camposDimensoesVolumes.volume.valor == ''"
                            v-on="on" v-if="campo.label == 'Volume (UN)'" fab class="elevation-1 ma-0" x-small dense @click="estadoVolumesExtras.confirmarEdicao()" color="primary">
                              <v-icon small color="white">fas fa-pen</v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="camposDimensoesVolumes.comprimento.valor == '' || camposDimensoesVolumes.largura.valor == '' || camposDimensoesVolumes.altura.valor == '' ||
                            camposDimensoesVolumes.cubagem.valor == '' || camposDimensoesVolumes.peso.valor == '' || camposDimensoesVolumes.volume.valor == ''"
                            v-on="on" v-if="campo.label == 'Volume (UN)'" fab class="elevation-1 ml-1" x-small dense @click="estadoVolumesExtras.limpar()" color="#FF0000">
                              <v-icon small color="white">fas fa-times</v-icon>
                            </v-btn>
                          </template>
                          <span>Cancelar</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Tabela Volumes Extras -->
          <v-card v-if="estadoVolumesExtras.qtd > 0" outlined style="margin-bottom: 15px;">
            <v-card-text>
              <v-data-table dense :headers="grid.produtos.cabecalho" hide-default-footer :items="estadoVolumesExtras.estadoAtual">

                <template #item.comprimento="{ item }">
                  {{ item.comprimento.valor }}
                </template>

                <template #item.largura="{ item }">
                  {{ item.largura.valor }}
                </template>

                <template #item.altura="{ item }">
                  {{ item.altura.valor }}
                </template>

                <template #item.cubagem="{ item }">
                  {{ item.cubagem.valor }}
                </template>

                <template #item.peso="{ item }">
                  {{ item.peso.valor }}
                </template>

                <template #item.volume="{ item }">
                  {{ item.volume.valor }}
                </template>

                <template #item.acoes="{ item, index }">
                  <div class="d-flex justify-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" type="button" icon class="mr-2" @click="estadoVolumesExtras.editar(index)">
                          <v-icon small color="primary">fas fa-pen</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon type="button" @click="estadoVolumesExtras.remover(index)">
                          <v-icon small color="red">fas fa-trash</v-icon>
                        </v-btn>
                      </template>
                      <span>Excluir Volume Extra</span>
                    </v-tooltip>
                  </div>
                </template>

              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
  </article>
</template>

<style>
  .fixed-bar {
    position: sticky !important;
    position: -webkit-sticky !important; /* for Safari */
    top: 4em;
    z-index: 2;
  }
</style>